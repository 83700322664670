import CustomerService from '@/services/CustomerService.js'
import CustomerCreditService from '@/services/CustomerCreditService.js'

export const namespaced = true

export const state = {
  dialog: {
    show: false,
    customer: null,
    bookings: [],
    invoices: [],
    receipts: [],
    editingRecords: [],
    customerCreditTimeline: null,
    isLoading: false,
    fetchingChunks: [],
    openTab: 'customer-information',
    isSmaller: false,
    bookingOverlay: {
      // OBS: finns ett liknande objekt i booking.js (booking.dialog), ändringar i något objekt borde kanske reflekteras i det andra
      show: false,
      isLoading: false,
      fetchingChunks: [],
      bookingId: null, // Sätts innan booking hämtats, för att säkerställa att rätt request skriver över data i dialogen
      openTab: {
        tabIndex: 'booking-information',
        subTabIndex: ''
      },
      booking: null,
      charges: [],
      invoices: [],
      receipts: [],
      emails: [],
      accommodationChargesCalendarItems: [],
      editingRecords: []
    }
  },

  isLoadingRegisterCustomerCreditPayment: false,
  isLoadingCustomerCreditPayment: false,
  isLoadingTerminalPayment: false,
  triggerRetryCustomerCreditPayment: 0,
  triggerCustomerUpdated: 0
}

export const mutations = {
  SET_SHOW_DIALOG (state, val) {
    state.dialog.show = val
  },
  SET_DIALOG_IS_SMALLER (state, val) {
    state.dialog.isSmaller = val
  },
  SET_DIALOG_OPEN_TAB (state, val) {
    state.dialog.openTab = val
  },
  SET_DIALOG_IS_LOADING (state, val) {
    state.dialog.isLoading = val
  },
  SET_DIALOG_FETCHING_CHUNKS (state, payload) {
    if (payload.isFetching) {
      const i = state.dialog.fetchingChunks.findIndex(id => id === payload.chunkName)
      if (i < 0) {
        state.dialog.fetchingChunks.push(payload.chunkName)
      }
    } else {
      const i = state.dialog.fetchingChunks.findIndex(id => id === payload.chunkName)
      state.dialog.fetchingChunks.splice(i, 1)
    }
  },
  SET_DIALOG_CUSTOMER (state, val) {
    state.dialog.customer = val
  },
  SET_DIALOG_CUSTOMER_BOOKINGS (state, val) {
    state.dialog.bookings = val
  },
  SET_DIALOG_CUSTOMER_INVOICES (state, val) {
    state.dialog.invoices = val
  },
  SET_DIALOG_CUSTOMER_RECEIPTS (state, val) {
    state.dialog.receipts = val
  },
  SET_DIALOG_CUSTOMER_EDITING_RECORDS (state, val) {
    state.dialog.editingRecords = val
  },
  SET_DIALOG_CUSTOMER_CREDIT_TIMELINE (state, val) {
    state.dialog.customerCreditTimeline = val
  },

  SET_BOOKING_OVERLAY_SHOW (state, val) {
    state.dialog.bookingOverlay.show = val
  },
  SET_BOOKING_OVERLAY_IS_LOADING (state, val) {
    state.dialog.bookingOverlay.isLoading = val
  },
  SET_BOOKING_OVERLAY_FETCHING_CHUNKS (state, payload) {
    if (payload.isFetching) {
      const i = state.dialog.bookingOverlay.fetchingChunks.findIndex(id => id === payload.chunkName)
      if (i < 0) {
        state.dialog.bookingOverlay.fetchingChunks.push(payload.chunkName)
      }
    } else {
      const i = state.dialog.bookingOverlay.fetchingChunks.findIndex(id => id === payload.chunkName)
      state.dialog.bookingOverlay.fetchingChunks.splice(i, 1)
    }
  },
  SET_BOOKING_OVERLAY_BOOKING (state, val) {
    state.dialog.bookingOverlay.booking = val
  },
  SET_BOOKING_OVERLAY_BOOKING_ID (state, val) {
    state.dialog.bookingOverlay.bookingId = val
  },
  SET_BOOKING_OVERLAY_BOOKING_REMARKS (state, val) {
    state.dialog.bookingOverlay.booking.remarks = val
  },
  SET_BOOKING_OVERLAY_BOOKING_CHARGES (state, val) {
    state.dialog.bookingOverlay.charges = val
  },
  SET_BOOKING_OVERLAY_BOOKING_INVOICES (state, val) {
    state.dialog.bookingOverlay.invoices = val
  },
  SET_BOOKING_OVERLAY_BOOKING_RECEIPTS (state, val) {
    state.dialog.bookingOverlay.receipts = val
  },
  SET_BOOKING_OVERLAY_BOOKING_EMAILS (state, val) {
    state.dialog.bookingOverlay.emails = val
  },
  SET_BOOKING_OVERLAY_BOOKING_EDITING_RECORDS (state, val) {
    state.dialog.bookingOverlay.editingRecords = val
  },
  SET_BOOKING_OVERLAY_BOOKING_CALENDAR_ITEMS (state, val) {
    state.dialog.bookingOverlay.accommodationChargesCalendarItems = val
  },
  ADD_BOOKING_OVERLAY_BOOKING_EMAIL (state, val) {
    const index = state.dialog.bookingOverlay.emails.findIndex(email => {
      return email.id === val.id
    })
    if (index >= 0) {
      state.dialog.bookingOverlay.emails.splice(index, 1, val)
    } else {
      state.dialog.bookingOverlay.emails.push(val)
    }
  },
  SET_BOOKING_OVERLAY_OPEN_TAB (state, { tabIndex, subTabIndex }) {
    if (tabIndex) {
      state.dialog.bookingOverlay.openTab.tabIndex = tabIndex
    }
    if (subTabIndex) {
      state.dialog.bookingOverlay.openTab.subTabIndex = subTabIndex
    }
  },

  SET_IS_LOADING_REGISTER_CUSTOMER_CREDIT_PAYMENT (state, val) {
    state.isLoadingRegisterCustomerCreditPayment = val
  },
  SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT (state, val) {
    state.isLoadingCustomerCreditPayment = val
  },
  SET_IS_LOADING_TERMINAL_PAYMENT (state, val) {
    state.isLoadingTerminalPayment = val
  },
  TRIGGER_RETRY_CUSTOMER_CREDIT_PAYMENT (state) {
    state.triggerRetryCustomerCreditPayment++
  },
  TRIGGER_CUSTOMER_UPDATED (state) {
    state.triggerCustomerUpdated++
  }
}

export const actions = {
  openDialogById ({ commit, dispatch }, { customerId, startTab, smaller = false }) {
    if (startTab) {
      commit('SET_DIALOG_OPEN_TAB', startTab)
    } else {
      commit('SET_DIALOG_OPEN_TAB', 'customer-information')
    }

    // parameter för att göra dialogen mindre, används t.ex. när customer dialog öppnas över booking dialog så att booking dialogen syns i bakgrunden
    commit('SET_DIALOG_IS_SMALLER', smaller)
    dispatch('resetCustomerDialog')
    commit('SET_DIALOG_IS_LOADING', true)
    commit('SET_SHOW_DIALOG', true)

    Promise.allSettled([
      dispatch('getCustomer', customerId),
      dispatch('getCustomerBookings', customerId),
      dispatch('getCustomerInvoices', customerId),
      dispatch('getCustomerReceipts', customerId),
      dispatch('getCustomerEditingRecords', customerId),
      dispatch('getCustomerCredit', customerId)
    ])
      .then(() => {
        commit('SET_DIALOG_IS_LOADING', false)
      })
  },
  resetCustomerDialog ({ commit }) {
    commit('SET_BOOKING_OVERLAY_SHOW', false)
    commit('SET_DIALOG_CUSTOMER', null)
    commit('SET_DIALOG_CUSTOMER_BOOKINGS', [])
    commit('SET_DIALOG_CUSTOMER_INVOICES', [])
    commit('SET_DIALOG_CUSTOMER_RECEIPTS', [])
    commit('SET_DIALOG_CUSTOMER_EDITING_RECORDS', [])
    commit('SET_DIALOG_CUSTOMER_CREDIT_TIMELINE', null)
  },
  getCustomer ({ commit }, customerId) {
    commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'customer', isFetching: true })
    return CustomerService.getCustomer(customerId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichCustomer(data.data.customer)
          commit('SET_DIALOG_CUSTOMER', data.data.customer)
        }
      })
      .finally(() => {
        commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'customer', isFetching: false })
      })
  },
  getCustomerCredit ({ commit }, customerId) {
    commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'customer-credit-timeline', isFetching: true })
    return CustomerCreditService.getCustomerCredit({ customerId, includeTimeline: true })
      .then(({ data }) => {
        console.log('getCustomerCredit data', data)
        if (data.status === 'success') {
          window.enrich.enrichCustomerCreditTimelineItem(data.data.credit_events)
          commit('SET_DIALOG_CUSTOMER_CREDIT_TIMELINE', {
            customerCredit: data.data.customer_credit,
            timelineItems: data.data.credit_events
          })
        }
      })
      .finally(() => {
        commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'customer-credit-timeline', isFetching: false })
      })
  },
  getCustomerBookings ({ commit }, customerId) {
    commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'bookings', isFetching: true })
    return CustomerService.getCustomerBookings(customerId)
      .then(({ data }) => {
        console.log('getCustomerBookings data', data)
        if (data.status === 'success') {
          window.enrich.enrichBooking(data.data.bookings)
          commit('SET_DIALOG_CUSTOMER_BOOKINGS', data.data.bookings)
        }
      })
      .finally(() => {
        commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'bookings', isFetching: false })
      })
  },
  getCustomerInvoices ({ commit }, customerId) {
    commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'invoices', isFetching: true })
    return CustomerService.getCustomerInvoices(customerId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichInvoice(data.data.invoices)
          commit('SET_DIALOG_CUSTOMER_INVOICES', data.data.invoices)
        }
      })
      .finally(() => {
        commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'invoices', isFetching: false })
      })
  },
  getCustomerReceipts ({ commit }, customerId) {
    commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'receipts', isFetching: true })
    return CustomerService.getCustomerReceipts(customerId)
      .then(({ data }) => {
        if (data.status === 'success') {
          window.enrich.enrichReceipt(data.data.receipts)
          commit('SET_DIALOG_CUSTOMER_RECEIPTS', data.data.receipts)
        }
      })
      .finally(() => {
        commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'receipts', isFetching: false })
      })
  },
  getCustomerEditingRecords ({ commit }, customerId) {
    commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'editing-records', isFetching: true })
    return CustomerService.getCustomerEditingRecords(customerId)
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_DIALOG_CUSTOMER_EDITING_RECORDS', data.data.editing_records)
        }
      })
      .finally(() => {
        commit('SET_DIALOG_FETCHING_CHUNKS', { chunkName: 'editing-records', isFetching: false })
      })
  },

  openBookingOverlay ({ state, commit, dispatch }, { bookingId, startTab }) {
    if (state.dialog.bookingOverlay.show && state.dialog.bookingOverlay.bookingId === bookingId) {
      // Öppnar inte dialog om redan öppen med samma booking
      return false
    }

    if (startTab) {
      if (['invoices', 'receipts'].includes(startTab)) {
        commit('SET_BOOKING_OVERLAY_OPEN_TAB', { tabIndex: 'invoices-receipts', subTabIndex: startTab })
      } else if (['accommodation-charges', 'other-charges'].includes(startTab)) {
        commit('SET_BOOKING_OVERLAY_OPEN_TAB', { tabIndex: 'charges', subTabIndex: startTab })
      } else {
        commit('SET_BOOKING_OVERLAY_OPEN_TAB', { tabIndex: startTab })
      }
    } else {
      commit('SET_BOOKING_OVERLAY_OPEN_TAB', { tabIndex: 'booking-information' })
    }

    commit('SET_BOOKING_OVERLAY_BOOKING_ID', bookingId) // dialog.bookingId garanterar att vi inte skriver över data i booking dialog med data tillhörande en annan booking
    dispatch('resetBookingOverlay')
    commit('SET_BOOKING_OVERLAY_IS_LOADING', true)
    commit('SET_BOOKING_OVERLAY_SHOW', true)

    return dispatch('booking/getBookingDialogContent', bookingId, { root: true })
      .then(() => {
        console.log('booking/getBookingDialogContent then')
        if (bookingId === state.dialog.bookingOverlay.bookingId) {
          commit('SET_BOOKING_OVERLAY_IS_LOADING', false)
        }
      })
  },
  resetBookingOverlay ({ commit }) {
    commit('SET_BOOKING_OVERLAY_BOOKING', null)
    commit('SET_BOOKING_OVERLAY_BOOKING_CHARGES', [])
    commit('SET_BOOKING_OVERLAY_BOOKING_INVOICES', [])
    commit('SET_BOOKING_OVERLAY_BOOKING_RECEIPTS', [])
    commit('SET_BOOKING_OVERLAY_BOOKING_EMAILS', [])
    commit('SET_BOOKING_OVERLAY_BOOKING_EDITING_RECORDS', [])
    commit('SET_BOOKING_OVERLAY_BOOKING_CALENDAR_ITEMS', [])
  },

  initCustomerCreditPayment ({ commit, dispatch }, { customer, paymentMethod, amount }) {
    commit('SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', true)
    if (paymentMethod && paymentMethod.gateway === 'integrated_terminal') {
      dispatch('posTerminal/resetStatusDialog', null, { root: true })
      commit('SET_IS_LOADING_TERMINAL_PAYMENT', true)
    }
    const paymentMethodId = paymentMethod.id
    const customerId = customer.id
    return CustomerCreditService.customerCreditPayment({ customerId, paymentMethodId, amount })
      .then(({ data }) => {
        if (data.status === 'success' && data.data.transaction_status === 'confirmed') {
          // Om transaction_status är await_confirmation uppdateras det när pusher kommer eller efter timeout
          commit('SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', false)
          commit('invoice/TRIGGER_CUSTOMER_CREDIT_UPDATED', null, { root: true })
        } else if (data.status === 'error') {
          commit('SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', false)
        }
      })
      .catch(() => {
        // Får error/snackbar från server
        commit('SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', false)
      })
  },
  registerCustomerCreditPayment ({ commit }, { customer, paymentMethod, account, date, amount }) {
    commit('SET_IS_LOADING_REGISTER_CUSTOMER_CREDIT_PAYMENT', true)
    const customerId = customer.id
    const paymentMethodId = paymentMethod.id
    const accountId = account.id
    return CustomerCreditService.registerCustomerCreditPayment({ customerId, paymentMethodId, accountId, date, amount })
      .then(({ data }) => {
        if (data.status === 'success') {
          // TODO:
        }
      })
      .finally(() => {
        commit('SET_IS_LOADING_REGISTER_CUSTOMER_CREDIT_PAYMENT', false)
      })
  }

}

export const getters = {
  customerTemplate (state, getters, rootState, rootGetters) {
    const country = rootGetters['country/defaultCountry']
    const customer = {
      id: 0,
      given_name: '',
      surname: '',
      org_number: '',
      org_name: '',
      org_reference: '',
      country_id: country.id,
      country,
      dialling_code_country_id: country.id,
      diallingCodeCountry: country,
      phone_number: '',
      email: '',
      street_name: '',
      street_number: '',
      zip: '',
      city: '',
      is_org: false,
      class: 'novice'
    }
    return customer
  }
}
