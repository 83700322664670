<template>
<v-dialog
  v-model="show"
  persistent
  max-width="450"
>
  <v-card>
    <v-card-title class="text-h5">
      <span class="text-h5 ">Kortterminal</span>
    </v-card-title>
    <v-card-text>
      <v-alert
        v-if="terminalErrorMessage != null"
        dense
        prominent
        outlined
        type="error"
      >
        <span>{{ terminalErrorMessage }}</span>
      </v-alert>
      <div v-if="terminalErrorPrint">
        <div
          v-if="terminalErrorPrint?.content?.length > 0"
          class="text--primary"
          center
        >
          Utskrift från terminalen:
        </div>
        <br>
        <div
          v-for="(line, i) in terminalErrorPrint?.content"
          :key="i"
        >
          <span>{{ line }}</span>
          <br>
        </div>
      </div>
      <div
        v-if="terminalErrorMessage == null"
        class="text--primary"
        center
      >
        {{ terminalDisplayText ?? 'Väntar på terminal...' }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="error"
        text
        :loading="terminalCancelling"
        @click="cancel()"
      >
        Avbryt
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="terminalErrorPrint != null"
        color="primary"
        text
        @click="print()"
      >
        Skriv ut
      </v-btn>
      <v-btn
        v-if="terminalErrorMessage != null"
        color="success"
        text
        @click="retry()"
      >
        Försök igen
      </v-btn>
      <div
        v-if="terminalRemainingTime > 0 && terminalErrorMessage == null"
        class="text-right text-caption text--disabled"
      >
        {{ terminalRemainingTime }}s
      </div>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TerminalStatusDialog',
  components: {},
  props: {},
  data: () => ({
    terminalProcessing: false,
    updateInterval: null,
    currentTimeout: 0
  }),
  computed: {
    ...mapState({
      terminalDisplayText: state => state.posTerminal.terminalDisplayText,
      terminalErrorMessage: state => state.posTerminal.terminalErrorMessage,
      terminalErrorPrint: state => state.posTerminal.terminalErrorPrint,
      terminalCancelling: state => state.posTerminal.terminalCancelling,
      retryPath: state => state.posTerminal.retryPath,
      activeReceipt: state => state.posCheckout.activeReceipt,
      terminalRemainingTime: state => state.posTerminal.terminalRemainingTime,
      isLoadingCustomerCreditPayment: state => state.customer.isLoadingCustomerCreditPayment
    }),
    show: function () {
      return this.activeReceipt?.receipt_status === 'awaiting_payment' || this.isLoadingCustomerCreditPayment
    },
    mode: function () {
      return this.activeReceipt?.receipt_status === 'awaiting_payment' ? 'receipt' : this.isLoadingCustomerCreditPayment ? 'customer_credit' : 'invoice'
    }
  },
  methods: {
    cancel: function () {
      if (this.terminalErrorMessage != null) {
        if (this.mode === 'receipt') {
          this.$store.commit('posCheckout/SET_ACTIVE_RECEIPT', null)
          this.$store.commit('posCheckout/SET_IS_LOADING_PAYMENT', false, { root: true })
        } else if (this.mode === 'customer_credit') {
          this.$store.commit('customer/SET_IS_LOADING_CUSTOMER_CREDIT_PAYMENT', false, { root: true })
        }
        this.$store.dispatch('posTerminal/resetStatusDialog')
      } else {
        this.$store.commit('posTerminal/SET_TERMINAL_CANCELLING', true)
        if (this.mode === 'receipt') {
          // Gör ett försök att avbryta men server som bestämmer om det går eller inte
          this.$store.dispatch('posTerminal/cancelReceiptPayment', this.activeReceipt.id)
        } else if (this.mode === 'customer_credit') {
          // TODO: behöver komma åt det på något sätt
          console.log('cancel - customer_credit')
        }
      }
    },
    print: function () {
      this.$store.dispatch('print/print', this.terminalErrorPrint?.object)
    },
    retry: function () {
      this.$store.dispatch('posTerminal/resetStatusDialog')
      if (this.mode === 'receipt') {
        this.$store.dispatch('posCheckout/retryPayment')
      } else if (this.mode === 'customer_credit') {
        this.$store.commit('customer/TRIGGER_RETRY_CUSTOMER_CREDIT_PAYMENT')
      }
    }
  }
}
</script>
