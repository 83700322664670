import PrinterService from '@/services/PrinterService.js'
export const namespaced = true

export const state = {
  printers: [],
  isFetchingPrinters: false,
  printObject: null,
  showDialog: false,
  htmlReceipt: ''
}

export const mutations = {
  SET_PRINTERS (state, val) {
    state.printers = val
  },
  SET_IS_FETCHING_PRINTERS (state, val) {
    state.isFetchingPrinters = val
  },
  SET_HTML_RECEIPT (state, val) {
    state.htmlReceipt = val
  },
  SET_SHOW_DIALOG (state, val) {
    state.showDialog = val
  }
}

export const actions = {
  fetchPrinters ({ commit }) {
    commit('SET_IS_FETCHING_PRINTERS', true)
    commit('SET_PRINTERS', [])

    return PrinterService.getPrinters()
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_PRINTERS', data.data.printers)
        }
      })
      .finally(() => {
        commit('SET_IS_FETCHING_PRINTERS', false)
      })
  },
  showPrint ({ commit }, print) {
    const printer = new Printer(print)
    commit('SET_HTML_RECEIPT', printer.html())
    commit('SET_SHOW_DIALOG', true)
  },
  async print ({ dispatch, rootState }, print) {
    if (rootState.workstation && rootState.workstation.default_printer) {
      print.port = rootState.workstation.default_printer.port
      print.width = rootState.workstation.default_printer.width
    } else {
      // Om ingen default skrivare så visar i dialog som default
      print.port = 'SIM'
      print.width = 42
    }

    if (print.port === 'SIM') {
      dispatch('showPrint', print)
      return 'success'
    } else {
      const result = await window.printer.printObject(print)
      return result
    }
  },
  printBong ({ dispatch, rootState }, { printerId, print }) {
    if (!rootState.workstation || rootState.workstation.printers.length === 0) {
      return false
    }
    const printer = rootState.workstation.printers.find(p => p.id === printerId)
    if (!printer) {
      return false
    }
    print.port = printer.port
    print.width = printer.width
    if (print.port === 'SIM') {
      dispatch('showPrint', print)
    } else {
      window.printer.printObject(print)
    }
  }
}

export const getters = {
  hasPrinter: function (state, getters, rootState) {
    return rootState.workstation && rootState.workstation.default_printer
  }
}

class Printer {
  constructor (printObject) {
    this.printObject = printObject
  }

  html () {
    this.print = '<div style="all:initial"><div class="paper"><div class="printarea">'
    this.currentAlignment = 'LEFT'
    this.isBold = false
    this.isQuad = false
    this.class = null
    this.style = null

    for (let i = 0; i < this.printObject.content.length; i++) {
      const line = this.printObject.content[i]
      switch (line.alignment) {
        case 'center':
          this.alignCenter()
          break
        case 'left':
          this.alignLeft()
          break
        case 'right':
          this.alignRight()
          break
        default:
          this.alignLeft()
          break
      }

      if (line.bold) {
        this.bold(true)
      } else {
        this.bold(false)
      }

      if ([0, 1, 2, 3, 4, 5, 6, 7].includes(line.text_size)) {
        this.setTextSize(line.text_size, line.text_size)
      } else {
        this.setTextSize(0, 0)
      }
      switch (line.type) {
        case 'image':
          this.printImage(line.value)
          break
        case 'text':
          this.println(line.value)
          break
        case 'left-right':
          // Anteckning: Left right fungerar bara med textstorlek 0 eller normal
          this.leftRight(line.value[0], line.value[1])
          break
        case 'newline':
          this.newLine()
          break
        case 'line':
          this.drawLine()
          break
        case 'table':
          // Anteckning: Table fungerar bara med textstorlek 0 eller normal
          line.value.forEach(row => {
            this.tableCustom(row)
          })
          break
        case 'open-cash-drawer':
          this.openCashDrawer()
          break
        case 'signal-buzzer':
          this.beep()
          break
        case 'cut':
          this.cut()
          break
      }
    };

    this.print += '</div></div></div>'
    return this.print
  }

  println (value) {
    value = this.replaceSpaces(value)
    this.class = ''
    if (this.isBold) {
      this.class += ' bold'
    }
    if (this.isQuad) {
      this.class += ' quad'
    }

    if (this.currentAlignment === 'CENTER') {
      this.style = 'style="text-align: center;"'
    } else if (this.currentAlignment === 'LEFT') {
      this.style = 'style="text-align: left;"'
    } else if (this.currentAlignment === 'RIGHT') {
      this.style = 'style="text-align: right;"'
    } else {
      this.style = ''
    }
    if (value === '' || value === ' ') {
      value = '<br>'
    }

    this.print += `<div ${this.style} class="${this.class}">${value}</div>`
  }

  leftRight (left, right) {
    left = this.replaceSpaces(left)
    right = this.replaceSpaces(right)
    this.class = 'printtable'
    if (this.isBold) {
      this.class += ' bold'
    }
    if (this.isQuad) {
      this.class += ' quad'
    }
    this.print += `<table class="${this.class}">`
    this.print += `<tr><td style="text-align: left;">${left}</td>
      <td style="text-align: right;">${right}</td></tr></table>`
  }

  printImage (image) {
    this.print += `<div><img src="/${image}" width="100%" class="center"></div>`
  }

  tableCustom (table) {
    this.print += '<table class="printtable"><tr>'
    table.forEach((row) => {
      this.print += `<td width="${row.width * 100}%" style="text-align: ${row.align};">${row.text}</td>`
    })
    this.print += '</tr></table>'
  }

  drawLine () {
    this.print += '<div>------------------------------------------</div>'
  }

  newLine () {
    this.print += '<br>'
  }

  bold (value) {
    this.isBold = value
  }

  alignRight () {
    this.currentAlignment = 'RIGHT'
  }

  alignLeft () {
    this.currentAlignment = 'LEFT'
  }

  alignCenter () {
    this.currentAlignment = 'CENTER'
  }

  setTextQuadArea () {
    this.isQuad = true
  }

  setTextSize (a, b) {
    if (a === 0) {
      this.isQuad = false
    } else {
      this.isQuad = true
    }
  }

  setTextNormal () {
    this.isQuad = false
  }

  cut () {
    this.print += '<div class="cut"></div>'
  }

  openCashDrawer () {
    return 0
  }

  beep () {
    return 0
  }

  replaceSpaces = (str) => {
    str = '' + str // castar till string
    return str.replace(/ /g, '&nbsp;')
  }
}
