import router from '@/router'
import CheckoutService from '@/services/CheckoutService.js'
import PaymentMethodService from '@/services/PaymentMethodService.js'

export const namespaced = true

export const state = {
  paymentMethods: [],
  isFetchingPaymentMethods: false,
  activeReceipt: null,
  isLoadingPayment: false,
  showReceiptDeliveryDialog: false,
  isProcessingDelivery: false
}

export const mutations = {
  SET_PAYMENT_METHODS (state, val) {
    state.paymentMethods = val
  },
  SET_IS_FETCHING_PAYMENT_METHODS (state, val) {
    state.isFetchingPaymentMethods = val
  },
  SET_ACTIVE_RECEIPT (state, val) {
    state.activeReceipt = val
  },
  SET_IS_LOADING_PAYMENT (state, val) {
    state.isLoadingPayment = val
  },
  SET_SHOW_RECEIPT_DELIVERY_DIALOG (state, val) {
    state.showReceiptDeliveryDialog = val
  },
  SET_IS_PROCESSING_DELIVERY (state, val) {
    state.isProcessingDelivery = val
  }
}

export const actions = {
  fetchPaymentMethods ({ commit }) {
    commit('SET_IS_FETCHING_PAYMENT_METHODS', true)
    return PaymentMethodService.fetchPaymentMethods()
      .then(({ data }) => {
        commit('SET_PAYMENT_METHODS', data.data.payment_methods)
        commit('SET_IS_FETCHING_PAYMENT_METHODS', false)
      })
  },
  initiateSales ({ rootState, dispatch, commit }, { paymentMethod, amount, booking, parkedOrder, charges, accommodationChargesAmount = 0, customerCreditAmount = 0, shouldCheckOut = false }) {
    commit('SET_IS_LOADING_PAYMENT', true)
    const requiresSignature = rootState.posRegister.register.current_control_unit?.requires_client_signature
    const initiatePayment = () => {
      const paymentMethodId = paymentMethod ? paymentMethod.id : null
      const bookingId = booking ? booking.id : null
      const parkedOrderId = parkedOrder ? parkedOrder.id : null
      dispatch('posTerminal/resetStatusDialog', null, { root: true })
      CheckoutService.initiate({ registerId: rootState.posRegister.register.id, paymentMethodId, amount, accommodationChargesAmount, customerCreditAmount, charges, bookingId, parkedOrderId, shouldCheckOut })
        .then(({ data }) => {
          if (data.status === 'success') {
            console.log('initiateSales success', data.data.receipt)
            dispatch('handleReceiptResponse', data.data.receipt)
          } else {
            commit('SET_IS_LOADING_PAYMENT', false)
          }
        })
        .catch((error) => {
          console.log('prepareSales error', error)
          commit('SET_IS_LOADING_PAYMENT', false)
        })
    }

    if (requiresSignature) {
      // Kontrollerar om CleanCash fungerar innan vi börjar
      dispatch('posErrors/checkCleanCash', undefined, { root: true })
        .then(initiatePayment)
        .catch((error) => {
          console.error('checkCleanCash error', error)
          commit('SET_IS_LOADING_PAYMENT', false)
        })
    } else {
      initiatePayment()
    }
  },
  handleReceiptResponse ({ commit, dispatch }, receipt) {
    commit('SET_ACTIVE_RECEIPT', receipt)

    if (receipt.receipt_status === 'awaiting_payment') {
      // Server tar hand om all terminalkommunikation och dialogdata
      const terminalTimeout = receipt.terminal_timeout
      console.log('awaiting payment', terminalTimeout)

      dispatch('posTerminal/startTerminalTimeout', {
        timeout: terminalTimeout,
        receiptId: receipt.id
      }, { root: true })
    } else {
      commit('posTerminal/SET_TERMINAL_REMAINING_TIME', null, { root: true })
    }

    if (receipt.receipt_status === 'awaiting_signature') {
      // Kommer bara hit om det är CleanCash
      dispatch('handleSignature')
    }

    if (receipt.receipt_status === 'awaiting_delivery') {
      dispatch('handleDelivery')
    }

    if (receipt.receipt_status === 'complete') {
      dispatch('handleCompletion')
    }

    if (receipt.receipt_status === 'error') {
      dispatch('handleError')
    }
  },
  async retryPayment ({ dispatch, state }) {
    const receiptId = state.activeReceipt.id
    CheckoutService.retryPayment({ receiptId })
      .then(({ data }) => {
        dispatch('handleReceiptResponse', data.data.receipt)
      })
  },
  async handleSignature ({ state, commit, dispatch }) {
    // Skickar till CleanCash
    const response = await window.cleancash.registerReceipt(state.activeReceipt.control_object)
    CheckoutService.signReceipt({ receiptId: state.activeReceipt.id, controlData: response })
      .then(({ data }) => {
        dispatch('handleReceiptResponse', data.data.receipt)
      })
      .catch((error) => {
        // Kontrollenheten eller servern avvisade signeringen, kvittot kommer sättas till error
        console.log('signReceipt error', error)
        commit('SET_IS_LOADING_PAYMENT', false)
      })
  },

  handleDelivery ({ commit, dispatch }) {
    dispatch('snackbars/createSnackbar', {
      color: 'success',
      text: 'Registrering genomförd'
    }, { root: true })
    commit('SET_IS_PROCESSING_DELIVERY', false)
    commit('SET_SHOW_RECEIPT_DELIVERY_DIALOG', true)
  },

  async confirmDelivery ({ state, dispatch, commit }, { receiptForm, email }) {
    commit('SET_IS_PROCESSING_DELIVERY', true)
    if (receiptForm === 'print') {
      const result = await dispatch('print/print', state.activeReceipt.print, { root: true })
      console.log('confirmDelivery print result', result)
      if (result !== 'success') {
        commit('SET_IS_PROCESSING_DELIVERY', false)
        dispatch('snackbars/createSnackbar', {
          color: 'error',
          text: 'Kvittot kunde inte skrivas ut'
        }, { root: true })
        return
      }
    }
    CheckoutService.deliverReceipt({
      receiptId: state.activeReceipt.id,
      receiptForm,
      email
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          commit('SET_SHOW_RECEIPT_DELIVERY_DIALOG', false)
          dispatch('handleReceiptResponse', data.data.receipt)
        } else if (data.status === 'error' && data.error_code === 'invalid_email') {
          // Loader försvinner och man får ange en ny email eller välja något annat
          dispatch('snackbars/createSnackbar', {
            color: 'error',
            text: data.error_message
          }, { root: true })
        } else {
          commit('SET_SHOW_RECEIPT_DELIVERY_DIALOG', false)
        }
      })
      .finally(() => {
        commit('SET_IS_PROCESSING_DELIVERY', false)
      })
  },
  getReceipt ({ state, dispatch }, receiptId) {
    if (receiptId !== state.activeReceipt?.id) {
      return
    }
    CheckoutService.getReceipt({ receiptId })
      .then(({ data }) => {
        dispatch('handleReceiptResponse', data.data.receipt)
      })
  },
  handleCompletion ({ state, dispatch, commit }) {
    if (state.activeReceipt.receipt_type === 'copy') {
      return
    }

    commit('SET_IS_LOADING_PAYMENT', false)

    const shouldCheckOut = state.activeReceipt.should_check_out
    const shouldResetCart = !shouldCheckOut
    const shouldCancelCart = shouldCheckOut

    if (shouldResetCart) {
      dispatch('posCart/reset', undefined, { root: true })
    } else if (shouldCancelCart) {
      dispatch('posCart/cancel', undefined, { root: true })
    }

    if (shouldCheckOut) {
      router.push({ name: 'ActionsItems' })
    }
  },
  handleError ({ commit, dispatch }) {
    commit('SET_ACTIVE_RECEIPT', null)
    commit('SET_IS_LOADING_PAYMENT', false)
    dispatch('snackbars/createSnackbar', {
      color: 'error',
      text: 'Kvittot kunde inte färdigställas'
    }, { root: true })
  }
}
